//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Text } from '@dt/material-components';
import { SecurityFindingSearchImportanceTagValues } from '@dt/graphql-support/types';
import type { SecurityFindingSearchImportanceTag } from '@dt/graphql-support/types';

export type PopoutFilterPluginStoreBlockerValues = {
  [key: SecurityFindingSearchImportanceTag]: boolean,
  ...,
};

export const PopoutFilterPluginStoreBlockerDisplayLookup: $Exact<
  $ObjMap<typeof SecurityFindingSearchImportanceTagValues, <V>() => ?string>,
> = {
  [SecurityFindingSearchImportanceTagValues.APPLE_P1]: 'App Store Blocker',
  [SecurityFindingSearchImportanceTagValues.GOOGLE_P1]: 'Google Play Store',

  // NOTE: Not all importance tags are accessible from this filter plugin.
  [SecurityFindingSearchImportanceTagValues.SECURITY_P1]: null,
  [SecurityFindingSearchImportanceTagValues.SECURITY]: null,
  [SecurityFindingSearchImportanceTagValues.APPLE]: null,
  [SecurityFindingSearchImportanceTagValues.GOOGLE]: null,
  [SecurityFindingSearchImportanceTagValues.DEFENSE_IN_DEPTH]: null,
  [SecurityFindingSearchImportanceTagValues.SERVER_SIDE]: null,
  [SecurityFindingSearchImportanceTagValues.CUSTOMER_POLICY]: null,
  [SecurityFindingSearchImportanceTagValues.KEYS_TO_THE_KINGDOM]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_LEAKS]: null,
  [SecurityFindingSearchImportanceTagValues.COFFEE_SHOP_COMPROMISE]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_CERTIFICATE_VALIDATION]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_HOSTNAME_VALIDATION]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_VULNERABLE_SDK]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_SECURE_NETWORK_REQUESTS]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_OPEN_SOCKETS]: null,
  [SecurityFindingSearchImportanceTagValues.CATEGORY_SSL_CLEARTEXT_COMMUNICATION]: null,
  [SecurityFindingSearchImportanceTagValues.APP_STORE_PRIVACY]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_INSTALLED_APPS]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_SMS_MESSAGES]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_CONTACT_INFO]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_CONTACT_LIST]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_PHOTOS]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_LOCATION]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_IDFA]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_HEALTH_AND_FITNESS]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_PASTEBOARD]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_CELLULAR_CARRIER]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_WIFI_NETWORK]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_MAC_ADDRESS]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_DEVICE_IDENTIFIER]: null,
  [SecurityFindingSearchImportanceTagValues.DATA_TYPE_UNKNOWN]: null,
};

type Props = {
  +value: PopoutFilterPluginStoreBlockerValues,
  +onChange: (
    (
      value: PopoutFilterPluginStoreBlockerValues,
    ) => PopoutFilterPluginStoreBlockerValues,
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginStoreBlocker = ({ value, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginStoreBlockerDisplayLookup)
        .filter(key => !!PopoutFilterPluginStoreBlockerDisplayLookup[key])
        .map(key => {
          const val = PopoutFilterPluginStoreBlockerDisplayLookup[key] || '';
          return (
            <FormControlLabel
              key={key}
              label={<Text variant="body">{val}</Text>}
              control={
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  checked={!!value[key]}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,
                        // $FlowFixMe - string -> enum
                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
            />
          );
        })}
    </Box>
  );
};
