//@flow
import React from 'react';
import { Chip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

type Props = {
  +label: string,
  +value: string,
  +disabled?: boolean,
  +onDelete?: () => void,
};

const FilterChip = withStyles(() => ({
  root: {
    background: palette.white,
    color: palette.gray20,
    border: `0.5px solid ${palette.gray20}`,
    marginTop: 8,
    marginRight: 8,
  },
  deleteIcon: {
    color: palette.gray20,
    '&:hover': {
      color: palette.gray30,
    },
  },
}))(Chip);

const PopoutFilterChipComponent = function PopoutFilterChip({
  label,
  value,
  disabled,
  onDelete,
}: Props) {
  return (
    <Box display="inline-flex">
      <FilterChip
        style={{ maxWidth: 200 }}
        size={'small'}
        label={`${label} = ${value}`}
        disabled={disabled}
        onDelete={onDelete}
      />
    </Box>
  );
};

export const getFilterChipMultipleValue = (
  options: any,
  optionDisplayLookup: any,
): string => {
  return (
    Object.entries(options)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => !!value)
      // eslint-disable-next-line no-unused-vars
      .map(([key, _]) => {
        if (optionDisplayLookup) {
          return `${optionDisplayLookup[key]}`;
        } else {
          return key;
        }
      })
      .join(', ')
  );
};

export const PopoutFilterChip = PopoutFilterChipComponent;
