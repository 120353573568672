//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Text } from '@dt/material-components';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/types';
import type { MobileAppReleaseType } from '@dt/graphql-support/types';
import Box from '@material-ui/core/Box';

export type PopoutFilterPluginMobileAppReleaseTypeValues = {
  [key: MobileAppReleaseType]: boolean,
  ...,
};

export const PopoutFilterPluginMobileAppReleaseTypeDisplayLookup: $Exact<
  $ObjMap<typeof MobileAppReleaseTypeValues, <V>() => string>,
> = {
  [MobileAppReleaseTypeValues.PRE_PROD]: 'PreProd Apps',
  [MobileAppReleaseTypeValues.APP_STORE]: 'Prod Apss',
  [MobileAppReleaseTypeValues.APP_STORE_THIRD_PARTY]: '3rd Party Prod Apps',
  [MobileAppReleaseTypeValues.ENTERPRISE]: 'Corporate Apps',
};

type Props = {
  +value: PopoutFilterPluginMobileAppReleaseTypeValues,
  +onChange: (
    (
      value: PopoutFilterPluginMobileAppReleaseTypeValues,
    ) => PopoutFilterPluginMobileAppReleaseTypeValues,
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginMobileAppReleaseType = ({
  value,
  onChange,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginMobileAppReleaseTypeDisplayLookup).map(
        key => {
          const val = PopoutFilterPluginMobileAppReleaseTypeDisplayLookup[key];
          return (
            <FormControlLabel
              key={key}
              label={<Text variant="body">{val}</Text>}
              control={
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  checked={!!value[key]}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,
                        // $FlowFixMe - string -> enum
                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
            />
          );
        },
      )}
    </Box>
  );
};
